import React from "react";

// components
import SelectInput from "../../../components/elements/form/SelectInput";
import TextBox from "../../../components/elements/form/TextBox";
import Button from "../../../components/elements/form/Button";
import { Modal } from "antd";

// constants
import { MdOutlineClose } from "react-icons/md";

// state
import { get_countries, get_states } from "../../../service/api/misc";
import { request_service } from "../../../service/api/service";
import { useAppDispatch, useAppSelector } from "../../../store";
import {
  IServiceRequestData,
  IServiceRequestDataError,
} from "../../../store/@types/service";

type IMakeRequest = {
  id: string;
  visible: boolean;
  onFinish: () => void;
};

const MakeRequest: React.FC<IMakeRequest> = ({ id, visible, onFinish }) => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(({ service }) => service.loading);
  const countries = useAppSelector(({ misc }) => misc.countries);
  const states = useAppSelector(({ misc }) => misc.states);
  const [data, dataSet] = React.useState<IServiceRequestData>({
    service_id: "",
    state_id: "",
    country_id: "",
    others: "",
  });

  const [error, errorSet] = React.useState<IServiceRequestDataError>({
    state_id: "",
    country_id: "",
    others: "",
    service_id: "",
  });

  const setId = React.useCallback(() => {
    if (id) dataSet((prev) => ({ ...prev, service_id: id }));
  }, [id]);

  const onLoad = React.useCallback(() => {
    get_countries(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
    setId();
  }, [onLoad, setId]);

  const onSubmit = async () => {
    if (!data.state_id) {
      errorSet((prev) => ({
        ...prev,
        state_id: "warning",
        country_id: "warning",
      }));
      return;
    }
    const response = await request_service(dispatch, data);
    if (response) onFinish?.();
  };

  return (
    <Modal open={visible} footer={null} closable={false} centered>
      <div>
        <div className="w-full flex justify-end">
          <MdOutlineClose
            size={17}
            className="cursor-pointer"
            onClick={() => {
              dataSet({
                service_id: "",
                state_id: "",
                country_id: "",
                others: "",
              });
              onFinish();
            }}
          />
        </div>

        <div className="my-3">
          <TextBox
            label="Others"
            name="others"
            placeholder="Enter addition or exception"
            status={error.others}
            value={data.others}
            onChange={(e) =>
              dataSet((prev) => ({ ...prev, others: e.target.value }))
            }
          />
        </div>

        <div className="flex justify-between">
          <div className="w-[49%]">
            <SelectInput
              label="Country"
              items={countries}
              name="country"
              value={data.country_id}
              status={error.country_id}
              onChange={(e) => {
                get_states(e.target.value, dispatch);
                dataSet((prev) => ({ ...prev, country_id: e.target.value }));
              }}
            />
          </div>

          <div className="w-[49%]">
            <SelectInput
              label="State"
              items={states}
              name="state_id"
              status={error.state_id}
              value={data.state_id}
              onChange={(e) => {
                dataSet((prev) => ({ ...prev, state_id: e.target.value }));
                errorSet((prev) => ({ ...prev, country_id: "", state_id: "" }));
              }}
            />
          </div>
        </div>

        <div className="my-4">
          <Button
            title="Make Request"
            onHandler={onSubmit}
            loading={loading}
            className="text-[white]"
          />
        </div>
      </div>
    </Modal>
  );
};

export default MakeRequest;
