import React from "react";
import { LuMapPin, LuBuilding, LuGlobe, LuPhone, LuMail } from "react-icons/lu";

// components
import TextInput from "../../../components/elements/form/TextInput";

// dispatch
import { useAppDispatch, useAppSelector } from "../../../store";
import {
  ICreateCompany,
  ICreateCompanyError,
} from "../../../store/@types/company";
import SelectInput from "../../../components/elements/form/SelectInput";
import { get_states } from "../../../service/api/misc";
import Button from "../../../components/elements/form/Button";
import { create_company } from "../../../service/api/company";
import { useNavigate } from "react-router-dom";

type IAddCompany = {
  rc_number: string;
};

const AddCompany: React.FC<IAddCompany> = ({ rc_number }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const loading = useAppSelector(({ company }) => company.loading);
  const countries = useAppSelector(({ misc }) => misc.countries);
  const states = useAppSelector(({ misc }) => misc.states);

  const [data, dataSet] = React.useState<ICreateCompany>({
    rc_number: rc_number,
    company_name: "",
    phone_number: "",
    company_email: "",
    company_website: "",
    company_address: "",
    country_id: "",
    state_id: "",
  });
  const [error, errorSet] = React.useState<ICreateCompanyError>({
    rc_number: "",
    company_name: "",
    phone_number: "",
    company_email: "",
    company_website: "",
    company_address: "",
    countr_id: "",
    state_id: "",
  });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dataSet((prev) => ({ ...prev, [name]: value }));
    errorSet((prev) => ({ ...prev, [name]: "" }));
  };

  const inputColor = "#67c748";

  return (
    <div className="bg-white rounded-xl p-6 mt-4 shadow-md">
      <div className="grid gap-4">
        <TextInput
          type="text"
          label_icon={<LuBuilding size={16} color={inputColor} />}
          label="Company Name"
          name="company_name"
          value={data.company_name}
          onChange={onChange}
          placeholder="Corporate Setup"
          required={true}
          status={error.company_name}
        />
        <TextInput
          type="text"
          label_icon={<LuMail size={16} color={inputColor} />}
          label="Company Email"
          name="company_email"
          value={data.company_email}
          onChange={onChange}
          placeholder="user@email.com"
          required={true}
          status={error.company_email}
        />
        <TextInput
          type="text"
          label_icon={<LuGlobe size={16} color={inputColor} />}
          label="Company Website"
          name="company_website"
          value={data.company_website}
          onChange={onChange}
          placeholder="https://corporate-setup.app"
          required={true}
          status={error.company_website}
        />
        <TextInput
          type="phone"
          label_icon={<LuPhone size={16} color={inputColor} />}
          label="Company Phone"
          name="phone_number"
          value={data.phone_number}
          onChange={onChange}
          placeholder="234 703 304 6371"
          required={true}
          status={error.phone_number}
        />
        <TextInput
          type="text"
          label_icon={<LuMapPin size={16} color={inputColor} />}
          label="Company Address"
          name="company_address"
          value={data.company_address}
          onChange={onChange}
          placeholder="Ogba-Ikeja"
          required={true}
          status={error.company_address}
        />
        <SelectInput
          label="Select Country"
          items={countries}
          name="country_id"
          value={data.country_id}
          onChange={(e) => {
            const { name, value } = e.target;
            get_states(value, dispatch);
            dataSet((prev) => ({ ...prev, [name]: value }));
            errorSet((prev) => ({ ...prev, [name]: "" }));
          }}
          // placeholder="Company Country"
          // required={true}
          status=""
        />
        <SelectInput
          label="Select State"
          items={states}
          name="state_id"
          value={data.state_id}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            const { name, value } = e.target;
            dataSet((prev) => ({ ...prev, [name]: value }));
            errorSet((prev) => ({ ...prev, [name]: "" }));
          }}
          // required={true}
          status={error?.state_id}
        />
      </div>

      <div className="mt-6">
        <div className="w-[30%]">
          <Button
            title="Register"
            loading={loading}
            onHandler={() => {
              let validate: boolean = false;

              if (!data.rc_number) {
                validate = true;
                errorSet((prev) => ({ ...prev, rc_number: "warning" }));
              }
              if (!data.company_name) {
                validate = true;
                errorSet((prev) => ({ ...prev, company_name: "warning" }));
              }
              if (!data.phone_number) {
                validate = true;
                errorSet((prev) => ({ ...prev, phone_number: "warning" }));
              }
              if (!data.company_email) {
                validate = true;
                errorSet((prev) => ({ ...prev, company_email: "warning" }));
              }
              if (!data.company_website) {
                validate = true;
                errorSet((prev) => ({ ...prev, company_website: "warning" }));
              }
              if (!data.company_address) {
                validate = true;
                errorSet((prev) => ({ ...prev, company_address: "warning" }));
              }
              if (!data.state_id) {
                validate = true;
                errorSet((prev) => ({ ...prev, state_id: "warning" }));
              }

              if (validate) return;
              create_company(dispatch, data, navigate);
            }}
            className="bg-gray-300 text-gray-300"
          />
        </div>
      </div>
    </div>
  );
};

export default AddCompany;
