import React from "react";
import { setTitle } from "../../../store/slices/navbar";
import { LuMapPin, LuPhone } from "react-icons/lu";
import EditProfile from "../misc/EditProfile";
import PasswordChange from "../misc/PasswordChange";
import { useAppDispatch, useAppSelector } from "../../../store";
import { get_profile } from "../../../service/api/auth";

const Setting = () => {
  const dispatch = useAppDispatch();
  const [update, updateSet] = React.useState<boolean>(false);
  const [password, passwordSet] = React.useState<boolean>(false);
  const user = useAppSelector(({ auth }) => auth.user);

  const onLoad = React.useCallback(() => {
    dispatch(setTitle("Settings"));
    get_profile(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  const onSetting = [
    { key: 1, name: "Edit profile", link: () => updateSet(true) },
    { key: 2, name: "Change Password", link: () => passwordSet(true) },
  ];

  const avatar =
    user?.profile?.avatar ||
    "https://cdn.pixabay.com/photo/2020/05/17/20/21/cat-5183427_960_720.jpg";

  return (
    <div className="w-full flex justify-center items-center p-4">
      <div className="w-full max-w-4xl rounded-2xl shadow-md bg-white mb-12 overflow-hidden">
        {/* Banner */}
        <div className="h-48 sm:h-64 md:h-96 w-full bg-orange-500 relative">
          <div
            className="absolute inset-0 w-full object-contain"
            style={{
              backgroundImage: `url(${avatar})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></div>
        </div>

        {/* Profile details */}
        <div className="p-4 sm:p-6 flex-grow relative">
          <div className="flex flex-col sm:flex-row items-center sm:items-start -mt-20 sm:-mt-24">
            {/* Profile picture */}
            <div className="flex-shrink-0 mb-4 sm:mb-0 sm:mr-6">
              <img
                className="w-32 h-32 sm:w-40 sm:h-40 rounded-full border-4 border-white shadow-lg object-cover"
                src="https://cdn.pixabay.com/photo/2020/05/17/20/21/cat-5183427_960_720.jpg" //{user?.profile?.avatar}
                alt="Profile"
              />
            </div>

            {/* Name, email, and buttons */}
            <div className="flex-grow mt-4 sm:mt-24 w-full sm:w-auto">
              <div className="flex flex-col lg:flex-row justify-between items-center sm:items-start">
                <div className="text-center sm:text-left mb-4 sm:mb-0">
                  <p className="text-dark text-xl sm:text-2xl font-bold">
                    {user?.profile?.name}
                  </p>
                  <p className="text-gray-500 text-lg sm:text-xl">
                    {user?.email}
                  </p>
                </div>
                <div className="flex flex-wrap justify-center sm:justify-start gap-3 sm:mt-2 md:mt-3 lg:mt-0 ">
                  {onSetting.map((item) => (
                    <div
                      key={item.key}
                      onClick={() => item.link()}
                      className="border rounded-xl shadow border-t-none py-2 px-4 sm:px-6 flex cursor-pointer text-sm sm:text-base odd:bg-primary-500 odd:text-white odd:hover:bg-primary-600 odd:hover:text-white odd:border-primary-500 even:bg-white even:text-dark even:hover:bg-primary-500 even:hover:text-white even:border-primary-500"
                    >
                      {item.name}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="border-t border-gray-50 mt-6 pt-6">
            <p className="text-dark text-base sm:text-lg font-medium flex items-center gap-3">
              <LuMapPin /> {user?.profile?.address}
            </p>
            <p className="text-dark text-base sm:text-lg font-medium mt-2 flex items-center gap-3">
              <LuPhone /> {user?.profile?.phone}
            </p>
          </div>
        </div>
      </div>

      <PasswordChange open={password} onClose={() => passwordSet(false)} />
      <EditProfile open={update} onClose={() => updateSet(false)} />
    </div>
  );
};

export default Setting;
