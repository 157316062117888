import ServiceLargeCard from "../../../components/ui/Cards/ServiceLargeCard";

const UnregisteredCompanyUser = () => {
  return (
    <div className="w-full h-screen grid md:grid-cols-2 place-content-center gap-6">
      <ServiceLargeCard
        title="Already have a company?"
        sub_title="Enter company details"
        button_title="Submit Company"
        link="/dashboard/company-update"
        more_info="Step 1 of 3 in company registration process"
      />
      <ServiceLargeCard
        title="Start a company?"
        sub_title="Incorporate now"
        button_title="Continue"
        link="/dashboard/services"
      />
    </div>
  );
};

export default UnregisteredCompanyUser;
