import React from "react";

// components
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";

type IPaginate = {
  current_page: number;
  last_page: number;
  onNext: () => void;
  onBack: () => void;
};

const Paginate: React.FC<IPaginate> = ({
  current_page,
  last_page,
  onNext,
  onBack,
}) => {
  const onPressNext = () => {
    if (current_page < last_page) onNext?.();
  };

  const onPressBack = () => {
    if (current_page > 1) onBack?.();
  };

  return current_page === 1 && last_page === 1 ? (
    <></>
  ) : (
    <div className="w-full flex justify-end md:py-4 py-[2px]">
      <div className="flex items-center text-[20px] mx-2">
        <GrLinkPrevious
          size={20}
          color="bggreen"
          onClick={onPressBack}
          className="mx-2 cursor-pointer"
        />
        {current_page}
      </div>
      |{/* Blinking forward arrow */}
      <div className="flex items-center text-[20px] mx-2">
        {last_page}
        <GrLinkNext
          size={20}
          color="bggreen"
          onClick={onPressNext}
          className="mx-2 cursor-pointer"
        />
      </div>
    </div>
  );
};

export default Paginate;
