import React from "react";

// components
import Content from "./content";
import SideBar from "./sidebar";
import NavigationBar from "./navbar";

type IAppTheme = {
  children: React.ReactNode;
};

const AppTheme: React.FC<IAppTheme> = ({ children }) => {
  const [open, openSet] = React.useState<boolean>(false);
  return (
    <div className="relative h-screen w-screen grid grid-rows-[8vh,1fr] sm:grid-cols-[4rem,1fr] md:grid-cols-[15rem,1fr] sm:grid-rows-[10vh,1fr] overflow-hidden bg-[#f4fcf1]">
      <SideBar open={open} onClose={() => openSet(!open)} />
      <div className="grid w-full h-screen grid-rows-[8vh,1fr]">
        <NavigationBar onSidebar={() => openSet(!open)} />
        <Content>{children}</Content>
      </div>
    </div>
  );
};

export default AppTheme;
