import React from "react";
import { useParams } from "react-router-dom";
import { FaCommentAlt, FaClock, FaFolder } from "react-icons/fa";
import { useAppDispatch, useAppSelector } from "../../../store";
import { get_blog, get_comments } from "../../../service/api/blog";
import { formatDate, formatDateTime } from "../../../service/convert/date";
import HeaderTitle from "../../../components/ui/title";
import CreateComment from "../misc/CreateComment";
import LikeBlog from "../misc/LikeBlog";

const ViewBlog = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const blog = useAppSelector(({ blog }) => blog.blog);
  const comments = useAppSelector(({ blog }) => blog.comments);

  React.useEffect(() => {
    if (id) {
      get_blog(dispatch, id);
      get_comments(dispatch, id);
    }
  }, [dispatch, id]);

  const date = blog?.created_at ? formatDate(blog.created_at) : "";

  return (
    <div className="container mx-auto p-6 sm:p-0">
      <div className="max-w-3xl mx-auto">
        <HeaderTitle title={blog?.title || ""} />

        <div className="flex items-center text-gray-500 space-x-4 mb-4">
          <span className="flex items-center">
            <FaClock className="mr-2" />
            {date}
          </span>
          <span className="flex items-center">
            <FaFolder className="mr-2" />
            {blog?.category?.name}
          </span>
        </div>

        <div className="mb-6 max-w-[48rem] max-h-[27rem]">
          <img
            src={blog?.image}
            alt={blog?.title}
            className="w-full h-full aspect-video object-cover rounded-lg"
          />
        </div>

        <div className="prose max-w-none mb-8">
          <div
            className="text-lg"
            dangerouslySetInnerHTML={{ __html: blog?.article || "" }}
          />
        </div>

        <div className="flex justify-between items-center mb-8">
          <div className="flex items-center space-x-2">
            <LikeBlog count={blog?.likes_count?.toString() || ""} />
            <div className="flex items-center">
              <FaCommentAlt className="mr-2 text-blue-500" />
              <span>{blog?.comments_count}</span>
            </div>
          </div>
        </div>

        <div className="border-t border-gray-200 pt-8">
          <h3 className="text-2xl font-bold mb-6">Comments</h3>
          <div className="space-y-6">
            {comments.map((comment) => (
              <div key={comment.id} className="bg-gray-50 p-4 rounded-lg">
                <div className="flex items-start space-x-4">
                  <div className="flex-shrink-0">
                    <img
                      src={comment?.user?.profile?.avatar}
                      alt={comment.user?.profile?.name}
                      className="w-12 h-12 rounded-full"
                    />
                  </div>
                  <div className="flex-grow">
                    <h6 className="font-semibold">
                      {comment?.user?.profile?.name}
                    </h6>
                    <p className="text-sm text-gray-500">
                      {comment?.created_at &&
                        formatDateTime(comment?.created_at)}
                    </p>
                    <p className="mt-2">{comment.comment}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="mt-8">
          <CreateComment />
        </div>
      </div>
    </div>
  );
};

export default ViewBlog;
