import React from "react";

// components
import Modal from "../../../components/ui/modal";
import TextInput from "../../../components/elements/form/TextInput";

// states
import { useAppDispatch, useAppSelector } from "../../../store";
import { IUpdateUser, IUpdateUserError } from "../../../store/@types/user";
import Button from "../../../components/elements/form/Button";
import { update } from "../../../service/api/auth";
import { FaTimes } from "react-icons/fa";

type IEdiProfile = {
  open: boolean;
  onClose: () => void;
};

const EdiProfile: React.FC<IEdiProfile> = ({ open, onClose }) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(({ auth }) => auth.user);
  const loading = useAppSelector(({ auth }) => auth.loading);
  const [data, dataSet] = React.useState<IUpdateUser>({
    address: "",
    name: "",
    nin_number: "",
    phone: "",
  });

  const [err, errSet] = React.useState<IUpdateUserError>({
    address: "",
    name: "",
    nin_number: "",
    phone: "",
  });

  const onLoad = React.useCallback(() => {
    if (user)
      dataSet({
        address: user?.profile?.address || "",
        name: user?.profile?.name || "",
        nin_number: user?.profile?.nin_number || "",
        phone: user?.profile?.phone || "",
      });
  }, [user]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dataSet((prev) => ({ ...prev, [name]: value }));
    errSet((prev) => ({ ...prev, [name]: "" }));
  };

  return (
    <Modal open={open}>
      <div className="flex w-full items-center justify-end">
        <div className="">
          <FaTimes
            onClick={() => {
              onClose();
              dataSet({
                address: "",
                name: "",
                nin_number: "",
                phone: "",
              });
            }}
            size={24}
            color="#9CA3AF"
            className="cursor-pointer"
          />
        </div>
      </div>

      <TextInput
        label="Full Name"
        name="name"
        placeholder="John Doe"
        required={true}
        type="text"
        value={data.name}
        status={err.name}
        onChange={handleChange}
      />

      <TextInput
        label="Phone Number"
        name="phone"
        placeholder="234 903 333 3333"
        required={true}
        type="text"
        value={data.phone}
        status={err.phone}
        onChange={handleChange}
      />

      <TextInput
        label="Address"
        name="address"
        placeholder="23, Osborne road"
        required={true}
        type="text"
        value={data.address}
        status={err.address}
        onChange={handleChange}
      />

      <TextInput
        label="Identity Number"
        name="nin_number"
        placeholder="1099955445"
        required={false}
        type="text"
        value={data.nin_number}
        status={err.nin_number}
        onChange={handleChange}
      />

      <div className="w-full mb-2 mt-4 flex justify-center items-center">
        <div className="w-[20%]">
          <Button
            title="Update"
            type="button"
            loading={loading}
            onHandler={async () => {
              let validate: boolean = false;
              if (!data.name) {
                validate = true;
                errSet((prev) => ({ ...prev, name: "" }));
              }
              if (!data.address) {
                validate = true;
                errSet((prev) => ({ ...prev, address: "" }));
              }
              if (!data.phone) {
                validate = true;
                errSet((prev) => ({ ...prev, phone: "" }));
              }
              if (validate) return;
              const response = await update(dispatch, data);
              if (response) {
                onClose();
                dataSet({
                  address: "",
                  name: "",
                  nin_number: "",
                  phone: "",
                });
              }
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default EdiProfile;
