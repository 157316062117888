import { Spin } from "antd";
import { IData, ITextInputErrorWarning } from "../../../store/@types/misc";

type ISelectInput = {
  label: string;
  name: string;
  status: ITextInputErrorWarning;
  loading?: boolean;
  items: IData[];
  icon?: React.ReactNode;
  value?: string;
  onChange: (e: any) => void;
};

const SelectInput: React.FC<ISelectInput> = ({
  label,
  items,
  name,
  status,
  onChange,
  icon,
  loading,
}) => {
  const borderColor = status ? "border-[red]" : "border-[#e5e7eb]";
  return (
    <div className="w-full">
      <label className="block text-md font-medium">{label}</label>
      <div
        className={`flex w-full border items-center rounded-lg mt-1 text-gray-900 px-3 py-2 ${borderColor} focus-within:ring focus-within:ring-primary-50`}
      >
        {icon && <div className="mr-1 border-r pr-2">{icon}</div>}
        <select
          onChange={onChange}
          name={name}
          className="w-full !outline-none px-0 py-0 border-none focus:border-transparent focus:ring-0 bg-white bg-opacity-0"
        >
          <option value="">Please Select</option>
          {items.map((item) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </select>
        {loading && <Spin size="small" />}
      </div>
    </div>
  );
};

export default SelectInput;
