import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IOpportunitiesResponse,
  IOpportunity,
  IOpportunityState,
} from "../@types/opportunity";

const initialState: IOpportunityState = {
  opportunities: [],
  current_page: 1,
  last_page: 1,
  opportunity: null,
  loading: false,
};

export const opportunity = createSlice({
  name: "opportunity",
  initialState,
  reducers: {
    setOpportunities: (
      state,
      action: PayloadAction<IOpportunitiesResponse>
    ) => {
      state.opportunities = action.payload.data;
      state.current_page = action.payload.current_page;
      state.last_page = action.payload.last_page;
    },
    setOpportunnity: (state, action: PayloadAction<IOpportunity>) => {
      state.opportunity = action.payload;
    },
    onNext: (state) => {
      state.current_page = state.current_page + 1;
    },
    onBack: (state) => {
      state.current_page = state.current_page - 1;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setOpportunities, setOpportunnity, onNext, onBack } =
  opportunity.actions;

export default opportunity.reducer;
