import React from "react";
import { ITextInputErrorWarning } from "../../../store/@types/misc";

type ITextBox = {
  label: string;
  name: string;
  value: string;
  type?: "text";
  placeholder: string;
  required?: boolean;
  status: ITextInputErrorWarning;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  icon?: React.ReactNode;
  loading?: boolean;
  className?: string;
};

const TextBox: React.FC<ITextBox> = ({
  label,
  name,
  value,
  placeholder,
  required,
  status,
  icon,
  className,
  onChange,
}) => {
  const borderColor = status ? "border-[red]" : "border-[#e5e7eb]";
  return (
    <div className="w-full">
      <label className="block font-medium">{label}</label>
      <div
        className={`flex w-full border items-center outline-none mt-1 text-gray-900 px-3 py-2 focus:shadow-sm bg-[white] focus-within:ring focus-within:ring-primary-50 rounded-lg ${borderColor}`}
      >
        {icon && <div className="mr-1 border-r pr-2">{icon}</div>}
        <textarea
          value={value}
          name={name}
          onChange={onChange}
          placeholder={placeholder}
          required={required}
          className={`w-full !outline-none px-0 py-0 placeholder:text-gray-400 border-none focus:border-transparent  focus:ring-0 ${className}`}
        ></textarea>
      </div>
    </div>
  );
};

export default TextBox;
