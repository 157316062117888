import axiosInstance from "../../utils/axiosInstance";

// loading
import {
  setService,
  setServices,
  setLoading,
} from "../../store/slices/service";
import { setMessage } from "../../store/slices/message";
import { AppDispatch } from "../../store/store";
import { isAxiosError } from "axios";

export const get_services = async (dispatch: AppDispatch, page: number = 1) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(`/get-services?page=${page}`);

    const { data } = response.data;
    dispatch(setServices(data));
  } catch (err) {
    // logger action
  }
  dispatch(setLoading());
};

export const get_service = async (dispatch: AppDispatch, id: string) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(`/get-service/${id}`);

    const { data } = response.data;
    dispatch(setService(data));
  } catch (err) {
    // logger action
  }
  dispatch(setLoading());
};

export const request_service = async (dispatch: AppDispatch, formData: any) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.post("/request-services", formData);

    const { message } = response.data;
    dispatch(setMessage({ type: "success", message: message }));
    dispatch(setLoading());
    return true;
  } catch (err) {
    if (isAxiosError(err)) {
      const { message } = err?.response?.data;
      dispatch(setMessage({ type: "error", message }));
    }
    dispatch(setLoading());
    return false;
  }
};

export const advert_services = async (dispatch: AppDispatch) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get("/get-advert-services");

    const { data } = response.data;
    dispatch(setServices(data));
  } catch (err) {
    if (isAxiosError(err)) {
      const { message } = err?.response?.data;
      console.log(message);
      dispatch(setMessage({ type: "error", message }));
    }
  }
  dispatch(setLoading());
};
