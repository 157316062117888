import React from "react";

// components
// import ServiceCard from "../misc/ServiceCard";
import Paginate from "../../../components/ui/paginate";

// state and service
import { get_services } from "../../../service/api/service";
import { onBack, onNext } from "../../../store/slices/service";
import { setTitle } from "../../../store/slices/navbar";
import ServiceCard from "../misc/ServiceCard";
import { useAppDispatch, useAppSelector } from "../../../store";

const ServiceDashboard = () => {
  const dispatch = useAppDispatch();
  const current_page = useAppSelector(({ service }) => service.current_page);
  const last_page = useAppSelector(({ service }) => service.last_page);
  const services = useAppSelector(({ service }) => service.services);

  const onLoad = React.useCallback(() => {
    dispatch(setTitle("Services"));
    get_services(dispatch, current_page);
  }, [current_page, dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="w-full mb-12">
      <div>
        <div className="h-full grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-6">
          {services.map((item) => (
            <ServiceCard key={item.id} item={item} />
          ))}
        </div>
      </div>

      <Paginate
        current_page={current_page}
        last_page={last_page}
        onBack={() => dispatch(onBack())}
        onNext={() => dispatch(onNext())}
      />
    </div>
  );
};

export default ServiceDashboard;
