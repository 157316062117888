import React from "react";

// router
import { useNavigate } from "react-router-dom";

// components
import Button from "../../elements/form/Button";

type IServiceLargeCard = {
  title: string;
  sub_title: string;
  more_info?: string;
  button_title: string;
  link: string;
};

const ServiceLargeCard: React.FC<IServiceLargeCard> = ({
  title,
  sub_title,
  more_info,
  button_title,
  link,
}) => {
  const navigate = useNavigate();

  return (
    <div className="h-full bg-[white] flex justify-between flex-col rounded-xl overflow-hidden p-6 shadow-md">
      <h1 className="text-left text-2xl font-semibold my-2">{title}</h1>
      <h1 className="text-left text-lg font-medium my-3">{sub_title}</h1>

      <Button
        title={button_title}
        type="button"
        onHandler={() => navigate(link)}
        bg_color="bg-primary-500"
        text_color="text-white"
      />
      <span className="mt-2 text-md text-gray-300 font-medium">
        {more_info}
      </span>
    </div>
  );
};

export default ServiceLargeCard;
