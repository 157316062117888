import React, { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import avatar from "../../../assets/images/svgs/dummy.svg";
import { confirm_image } from "../../../service/validate/image_confirm";
import { IService } from "../../../store/@types/service";

type IServiceCard = {
  item: IService;
};

const ServiceCard: React.FC<IServiceCard> = ({ item }) => {
  const navigate = useNavigate();
  const [image, setImage] = useState("");
  const [imageLoaded, setImageLoaded] = useState(false);

  const onLoad = useCallback(async () => {
    if (item?.description) {
      const confirmedImage = await confirm_image(item?.description);
      setImage(confirmedImage ? item?.description : avatar);
    }
  }, [item?.description]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <div
      onClick={() => navigate("/dashboard/services/" + item?.id)}
      className="w-full h-[312px] border border-primary-700 rounded-tl-2xl rounded-br-2xl overflow-hidden flex flex-col bg-white hover:shadow-lg transition-shadow duration-300"
    >
      <div className="relative w-full h-[70%]">
        <div
          className={`absolute inset-0 bg-primary-200 text-xl text-gray-500 font-semibold grid place-content-center ${
            imageLoaded ? "opacity-0" : "opacity-100"
          } transition-opacity duration-300 ease-in-out`}
          style={{ backdropFilter: "blur(10px)" }}
        >
          400x400
        </div>
        <img
          loading="lazy"
          src={item?.image || image}
          alt={item?.name}
          className="w-full h-full aspect-square object-cover"
          onLoad={handleImageLoad}
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent"></div>
      </div>
      <div className="flex-1 flex items-center justify-center p-4">
        <h3 className="text-lg font-semibold text-gray-800">{item?.name}</h3>
      </div>
    </div>
  );
};

export default ServiceCard;
