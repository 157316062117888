import React from "react";
import { useNavigate } from "react-router-dom";

// components

import logo from "../../../assets/images/icons/green-logo.png";
import login from "../../../assets/images/icons/login.png";
import ButtonLink from "../../../components/ui/links/ButtonLink";

// constants
import { IoMenuOutline, IoCloseSharp } from "react-icons/io5";

// state and dispatch
import { get_profile } from "../../../service/api/auth";
import { useAppDispatch, useAppSelector } from "../../../store";
import NavBarItem from "./NavBarItem";

const Navbar: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector(({ auth }) => auth.user);
  const [open, setOpen] = React.useState<boolean>(false);

  const onLoad = React.useCallback(() => {
    get_profile(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <nav className="grid lg:grid-cols-3 grid-cols-2 items-center py-2 px-4 font-abel relative">
      <div className="company-logo">
        <div className="h-[3.125rem]">
          <img
            src={logo}
            className="h-full object-contain cursor-pointer"
            alt="icon"
            onClick={() => navigate("/")}
          />
        </div>
      </div>

      {/* Navigation Links */}
      <NavBarItem open={open} onClick={() => setOpen(false)} />
      {/* </nav> */}

      {/* Login & Registration Button */}
      <div className="">
        {user ? (
          <div className="flex items-center justify-end">
            <img
              src={user?.profile?.avatar}
              alt={user?.profile?.name}
              onClick={() => navigate("/dashboard")}
              className="h-[3.125rem] w-[3.125rem] rounded-full border-primary border object-cover cursor-pointer hidden md:block"
            />
          </div>
        ) : (
          <div className="">
            <div className="visible flex items-center justify-end lg:hidden">
              <img
                src={login}
                className="w-[1.5rem] cursor-pointer"
                alt="login logo"
                onClick={() => navigate("/login")}
              />

              {open ? (
                <IoCloseSharp
                  onClick={() => setOpen(false)}
                  size={35}
                  className="lg:hidden mr-2 cursor-pointer"
                />
              ) : (
                <IoMenuOutline
                  onClick={() => setOpen(true)}
                  size={35}
                  className="lg:hidden ml-2 cursor-pointer"
                />
              )}
            </div>

            <div className="hidden lg:flex lg:items-center lg:justify-end lg:w-full">
              <div>
                <ButtonLink
                  title="Login"
                  link="/login"
                  className="text-primary-500 hover:text-white border border-primary-500 hover:border-primary-200 md:mr-2 hover:bg-primary-200 transition ease-in-out duration-300"
                />
              </div>
              <div>
                <ButtonLink
                  title="Register"
                  link="/register"
                  className="text-[white] ml-2 bg-primary text-md font-medium bg-primary-500 hover:bg-primary-800 border border-primary-500 hover:border-primary-800 transition ease-in-out duration-300"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
