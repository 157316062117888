import React from "react";

// router
import { useNavigate } from "react-router-dom";

// constant
import { IOpportunity } from "../../../store/@types/opportunity";

type IOpportunityCard = {
  item: IOpportunity;
};

const OpportunityCard: React.FC<IOpportunityCard> = ({ item }) => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => navigate(`/dashboard/opportunity/${item?.id}`)}
      className="w-full md:w-[32%] h-[60vh] md:h-[40vh] overflow-hidden mx-[2px] rounded-tl-2xl rounded-br-2xl border cursor-pointer md:mt-2 mt-2"
    >
      <div className="overflow-hidden w-full h-[80%] bg-[#d9d9d]">
        <img
          src={item?.opportunity_image}
          alt={item?.opportunity_name}
          className="object-cover w-full h-full"
        />
      </div>
      <div className="h-[20%] text-[white] text-[14.5px] md:text-[17px] font-bold capitalize bg-opacity-70 bg-[#060809] px-2 py-3">
        {item?.opportunity_name}
      </div>
    </div>
  );
};

export default OpportunityCard;
