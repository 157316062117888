import React from "react";

// components
import TrainingCard from "../misc/TrainingCard";
import HeaderTitle from "../../../components/ui/title";
import Paginate from "../../../components/ui/paginate";

// state
import { get_trainings } from "../../../service/api/training";
import { useAppDispatch, useAppSelector } from "../../../store";

const Training = () => {
  const dispatch = useAppDispatch();
  const last_page = useAppSelector(({ opportunity }) => opportunity.last_page);
  const current_page = useAppSelector(({ training }) => training.current_page);
  const trainings = useAppSelector(({ training }) => training.trainings);

  const onLoad = React.useCallback(() => {
    get_trainings(dispatch, current_page);
  }, [current_page, dispatch]);
  // console.log(trainings);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="w-full px-3 py-5 h-full">
      <HeaderTitle
        title="Corporate Setup Trainings"
        sub_title="All you need know about our training programs."
      />
      <div className="flex flex-wrap justify-between h-full bg-[white] px-4 py-5">
        {trainings.map((item) => (
          <TrainingCard key={item.id} item={item} />
        ))}
      </div>

      <Paginate
        current_page={current_page}
        last_page={last_page}
        onBack={() => null}
        onNext={() => null}
      />
    </div>
  );
};

export default Training;
