import React from "react";
import useShow from "../hooks/useShow";

// react router and states
import { get_opporunity } from "../../../service/api/opportunity";
import { Link, useParams } from "react-router-dom";
import { setTitle } from "../../../store/slices/navbar";
import { useAppDispatch, useAppSelector } from "../../../store";
import { formatDate } from "../../../service/convert/date";

const OpportunityDetails = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const opportunity = useAppSelector(
    ({ opportunity }) => opportunity.opportunity
  );

  const { image, download } = useShow();

  const onLoad = React.useCallback(() => {
    dispatch(setTitle("Opportunity"));
    if (id) get_opporunity(dispatch, id);
  }, [dispatch, id]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  console.log(opportunity);
  return (
    <div className="w-full md:px-3 md:py-5 h-full">
      <div className="flex flex-col h-full bg-[white] md:px-4 md:py-5 mt-1 md:mt-0  overflow-y-auto overflow-x-hidden">
        <div className="w-full h-[50vh] py-1">
          <img
            src={image}
            alt={opportunity?.opportunity_name}
            className="object-contain w-full h-full"
          />
        </div>

        <div className="w-full md:py-5 py-2">
          <div className="w-full flex justify-between">
            <p>
              {opportunity?.expire_date && formatDate(opportunity?.expire_date)}
            </p>
            <p>{opportunity?.opportunity_name}</p>
            <Link
              to={download}
              target="_blank"
              download={download ? true : false}
              className="cursor-pointer bg-primary-400 px-3 py-2 rounded-md text-white"
            >
              See File
            </Link>
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: opportunity?.opportunity_description || "",
            }}
            className="text-[16px] md:text-[18px] md:py-4 py-2"
          />
        </div>
      </div>
    </div>
  );
};

export default OpportunityDetails;
