// components
import NewLetterInput from "../../../components/elements/form/NewLetterInput";
import { Divider } from "antd";

// link router
import { Link } from "react-router-dom";

// constanst
import constants from "../../../constants/footer";

import foot1 from "../../../assets/images/statics/corporate-licence.png";
import ios from "../../../assets/images/statics/Apple.png";
import android from "../../../assets/images/statics/Android.png";
import logo from "../../../assets/images/icons/logo.png";

const Footer = () => {
  // const [email, emailSet] = React.useState("");
  return (
    <footer className="bg-black dark:bg-gray-900 w-full">
      <div className="w-full flex flex-wrap md:grid md:gap-5 mb-6 md:mb-0 md:px-4 py-4 md:py-8 md:grid-cols-5">
        {constants.nav.map((item) => (
          <div
            className="md:w-full w-[50%] text-center md:text-left"
            key={item.title}
          >
            <h2 className="md:text-sm font-semibold text-white uppercase dark:text-gray-400">
              {item.title}
            </h2>
            <ul className="text-gray-500 dark:text-gray-400 text-sm md:text-lg">
              {item.subtitle.map((data) => (
                <li className="mb-3" key={data.title}>
                  <Link to={data.link} className="hover:text-white">
                    {data.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>

      <div className="text-white ">
        <Divider className="text-white" />
      </div>

      <div className="sm:flex sm:items-center sm:justify-between">
        <ul className="block items-center mx-2 md:mb-0 mb-8">
          <img
            src={logo}
            className="h-[70px] md:h-[80px]"
            alt="corporate Logo"
          />
          <li className="text-white mx-2 mb-2">Available on web and also on</li>
          <div className="flex flex-items">
            <li>
              <button
                type="submit"
                className="group flex flex-items items-center mx-1 relative justify-center rounded-md bg-bggray py-2 px-2 text-sm font-semibold text-white hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
              >
                <img className="h-7" src={android} alt="Android Link" />
                Android
              </button>
            </li>

            <li>
              <button
                type="submit"
                className="group flex flex-items items-center mx-1 relative justify-center rounded-md bg-bggray py-2 px-3 text-sm font-semibold text-white hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
              >
                <img className="h-7" src={ios} alt="IOS Link to download" />
                iOS
              </button>
            </li>
          </div>
        </ul>

        <ul className="items-center px-3 text-sm text-white sm:mb-0 dark:text-gray-400">
          <li className="mr-4 hover:bggreen md:mr-6 text-md md:text-lg mb-3">
            Get Started with Us
          </li>
          <li className="mb-3">
            <NewLetterInput
              name="email"
              // value={email}
              onChange={() => {}}
              placeholder="Your Email"
            />
          </li>
          <li className="mb-3">
            <button
              type="submit"
              className="group relative py-3 flex w-60 justify-center rounded-md bg-primary-500  px-3 text-sm font-semibold text-white hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
            >
              Sign Up
            </button>
          </li>
        </ul>
      </div>

      <div className="px-3 py-6 bg-black dark:bg-gray-700 md:flex md:items-center md:justify-between">
        <span className="text-sm text-gray-500 dark:text-gray-300 sm:text-center">
          © 2023{" "}
          <Link to="#" className="text-white">
            Corporate Set-up
          </Link>
          . All Rights Reserved.
        </span>
        <div className="flex mt-4 space-x-6 sm:justify-center md:mt-0">
          <Link
            to={"#"}
            className="text-gray-400 hover:text-gray-900 dark:hover:text-white"
          >
            <img className="w-32 h-32" src={foot1} alt="Our Facebook account" />
            <span className="sr-only">Facebook page</span>
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
