import React from "react";
import Nav from "../../../constants/nav";
import { NavLink } from "react-router-dom";

type INavBarItem = {
  open: boolean;
  onClick: () => void;
};

const NavBarItem: React.FC<INavBarItem> = ({ open, onClick }) => {
  const nav_ref = React.useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (nav_ref.current && !nav_ref.current.contains(event.target as Node)) {
      onClick();
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  return (
    <div
      ref={nav_ref}
      className={`${
        open
          ? "absolute top-[95%] grid bg-[white] px-10 py-4 right-4 rounded-md border z-10 transition-all duration-500"
          : "hidden"
      } lg:flex lg:relative
      `}
    >
      {Nav?.map((item, index) => (
        <NavLink
          key={index + Math.random() * 2000}
          to={item.link}
          onClick={onClick}
          className={({ isActive, isPending }) =>
            `${
              isPending ||
              (isActive
                ? "border-b border-primary-500 text-gray-400"
                : "text-primary-800")
            } font-medium hover:text-gray-400 py-3 lg:py-2 px-5 lg:px-3 text-center transition-all duration-500`
          }
        >
          <span className="text-center text-[1rem]" key={item.title}>
            {item.title}
          </span>
        </NavLink>
      ))}
    </div>
  );
};

export default NavBarItem;
