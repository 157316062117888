import React from "react";

// components
import Button from "../../../components/elements/form/Button";
import MakeRequest from "../misc/MakeRequest";
import SweetAlert from "../../../components/ui/popup";
import { FaCheckCircle } from "react-icons/fa";

// router
import { useParams } from "react-router-dom";

// state
import { get_service } from "../../../service/api/service";
import { setTitle } from "../../../store/slices/navbar.js";
import { useAppDispatch, useAppSelector } from "../../../store";
// import useService from "../hooks/useService.js";

const ViewService: React.FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const service = useAppSelector(({ service }) => service.service);
  const loading = useAppSelector(({ loading }) => loading.loading);
  // const {} = useService();
  const [request, requestSet] = React.useState(false);

  const onLoad = React.useCallback(() => {
    dispatch(setTitle("Services"));
    if (id) get_service(dispatch, id);
  }, [dispatch, id]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="h-full w-full text-white ">
      <h2 className="text-3xl font-bold text-left capitalize text-dark">
        {service?.name}
      </h2>

      <div className="flex flex-col lg:flex-row gap-8 mt-8">
        <div className="w-full h-[60%] lg:w-1/2">
          <div className="rounded-xl shadow-lg aspect-video overflow-hidden">
            <img
              src={service?.image}
              className="w-full h-full object-cover"
              alt={service?.name}
            />
          </div>
          <div
            className="text-lg font-medium text-gray-800 p-4 "
            dangerouslySetInnerHTML={{ __html: service?.description || "" }}
          />
        </div>

        <div className="w-full lg:w-1/2 space-y-6">
          <h3 className="text-2xl font-bold text-primary-700">
            What's Included
          </h3>
          <div className="bg-white rounded-lg shadow-lg overflow-hidden">
            {service?.packages.map((item, index) => (
              <div
                className={`text-gray-800 text-lg font-medium capitalize p-4 flex items-center gap-3 ${
                  index !== service.packages.length - 1
                    ? "border-b border-[#cfcfcf]"
                    : ""
                }`}
                key={item?.id}
              >
                <FaCheckCircle className="text-primary-200" /> {item.name}
              </div>
            ))}
          </div>
          <div className="flex justify-center w-[25%]">
            <Button
              type="button"
              title="Continue"
              loading={loading}
              onHandler={() => requestSet(true)}
              className="px-6 py-2 text-sm bg-secondary-500 hover:bg-secondary-600 text-white font-semibold rounded-lg transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-secondary-400 focus:ring-opacity-50"
            />
          </div>
        </div>
      </div>

      <MakeRequest
        id={service?.id || ""}
        visible={request}
        onFinish={() => requestSet(false)}
      />
      <SweetAlert />
    </div>
  );
};

export default ViewService;
