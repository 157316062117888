import axiosInstance from "../../utils/axiosInstance";

// notification
// import { notify } from "../../components/alerts";

// loading
import {
  setOpportunities,
  setOpportunnity,
} from "../../store/slices/opportunity";
import { setLoading, stopLoading } from "../../store/slices/loading";
import { AppDispatch } from "../../store/store";

export const get_opportunities = async (
  dispatch: AppDispatch,
  current_page: number = 1
) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(
      `/v1/get-opportunities?page=${current_page}`
    );

    const { data } = response.data;
    dispatch(setOpportunities(data));
  } catch (err) {
    // logger action
  }
  dispatch(stopLoading());
};

export const get_opporunity = async (dispatch: AppDispatch, id: string) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.get(`/v1/get-opportunity/${id}`);

    const { data } = response.data;
    dispatch(setOpportunnity(data));
  } catch (err) {
    // logger action
  }
  dispatch(stopLoading());
};
