import React from "react";
import { Spin } from "antd";
import { ITextInputErrorWarning } from "../../../store/@types/misc";

export type ITextInput = {
  label: string;
  label_icon?: React.ReactNode;
  name: string;
  value: string;
  type: "text" | "number" | "date" | "password" | "phone";
  placeholder: string;
  required: boolean;
  status?: ITextInputErrorWarning;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  icon?: React.ReactNode;
  loading?: boolean;
  onBlur?: () => void;
};

const TextInput: React.FC<ITextInput> = ({
  label,
  label_icon,
  name,
  value,
  type,
  placeholder,
  required,
  status,
  onChange,
  icon,
  loading,
  onBlur,
}) => {
  const borderColor = status ? "border-[red]" : "border-[#e5e7eb]";
  return (
    <div className="w-full">
      <label className="flex gap-3 items-center text-md font-medium">
        {label_icon} {label}
      </label>
      <div
        className={`flex w-full border items-center rounded-lg mt-1 text-gray-900 px-3 py-2 bg-[white] ${borderColor} focus-within:ring focus-within:ring-primary-300`}
      >
        {icon && <div className="mr-1 border-r pr-2">{icon}</div>}
        <input
          type={type}
          value={value}
          name={name}
          onChange={onChange}
          placeholder={placeholder}
          required={required}
          className="w-full !outline-none px-0 py-0 placeholder:text-gray-400 border-none focus:border-transparent focus:ring-0"
          onBlur={onBlur}
        />
        {loading && <Spin size="small" />}
      </div>
    </div>
  );
};

export default TextInput;
