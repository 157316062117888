import React from "react";
import useCompany from "../hooks/useCompany";

// components
import AddBranch from "../misc/AddBranch";
import AddCompany from "../misc/AddCompany";
import GetRCNumber from "../misc/GetRCNumber";
import Button from "../../../components/elements/form/Button";
import { LuBuilding2, LuPhone, LuCalendar, LuMapPin } from "react-icons/lu";

// state
import { get_countries } from "../../../service/api/misc";
import { join_branch } from "../../../service/api/company";
import { useAppDispatch, useAppSelector } from "../../../store";
import { formatDate } from "../../../service/convert/date";
import { IJoinCompanyBranch } from "../../../store/@types/company";
import { useNavigate } from "react-router-dom";

const Company = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const loading = useAppSelector(({ loading }) => loading.loading);
  const company = useAppSelector(({ company }) => company.company);
  const [id, idSet] = React.useState<string>("");
  const { data, show, showSet, dataSet } = useCompany();

  const [branch, branchSet] = React.useState<IJoinCompanyBranch>({
    branch_id: "",
  });

  const onLoad = React.useCallback(() => {
    get_countries(dispatch);
    if (company) idSet(company?.id);
  }, [company, dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);
  console.log(branch);

  return (
    <div className="w-full mb-12">
      <h3 className="text-[1.5rem] font-[700] text-gray-400">
        Let us sync you with your company
      </h3>

      <div className="w-full xl:w-[50%] h-full mt-8">
        <div className="">
          <GetRCNumber
            getRCNumber={(res) =>
              dataSet((prev) => ({ ...prev, rc_number: res.rc_number }))
            }
          />
        </div>

        {company && show && (
          <div className="text-dark w-full mt-6">
            <div className="bg-white rounded-2xl shadow-md">
              <div
                className="h-52 bg-cover bg-center rounded-t-lg relative overflow-hidden"
                style={{
                  backgroundImage: `url('https://cdn.pixabay.com/photo/2016/11/19/13/08/business-1839191_960_720.jpg')`,
                }}
              >
                <div className="absolute inset-0 bg-gradient-to-t from-dark via-dark/50 to-transparent">
                  <div className="absolute bottom-0 left-0 right-0 p-4">
                    <h4 className="text-2xl font-semibold text-white capitalize">
                      {company?.company_name}
                    </h4>
                    <p className="text-white">{company?.company_website}</p>
                  </div>
                </div>
              </div>

              <div className="p-6 grid lg:grid-cols-2 gap-4">
                <div>
                  <span className="text-sm text-gray-500 font-medium">
                    RC Number
                  </span>
                  <p className="text-lg font-medium">{company?.rc_number}</p>
                </div>
                <div>
                  <span className="text-sm text-gray-500 font-medium">
                    Email
                  </span>
                  <p className="text-lg font-medium">
                    {company?.company_email}
                  </p>
                </div>
                <div>
                  <span className="text-sm text-gray-500 font-medium">
                    Phone
                  </span>
                  <p className="text-lg font-medium">
                    {company?.company_phone}
                  </p>
                </div>
                <div>
                  <span className="text-sm text-gray-500 font-medium">
                    Status
                  </span>
                  <p className="text-lg font-medium">{company?.status}</p>
                </div>
                {/* <h3>{company?.banner}</h3> */}
              </div>
            </div>

            <div className="grid gap-4 mt-6 ">
              <h4 className="flex items-center gap-2 text-2xl font-semibold text-primary-700 capitalize">
                <LuBuilding2 /> <span>Company Branch(s)</span>
              </h4>
              {company?.branch.map((b) => (
                <div
                  key={b.id}
                  className={`p-6 cursor-pointer rounded-2xl shadow-md  ${
                    branch.branch_id === b.id
                      ? "bg-primary-300 text-white"
                      : "bg-white"
                  }`}
                  onClick={() => {
                    if (branch.branch_id === b.id) branchSet({ branch_id: "" });
                    else branchSet({ branch_id: b.id });
                  }}
                >
                  <h5
                    className={`text-xl  font-semibold mb-2 capitalize ${
                      branch.branch_id === b.id
                        ? "text-white"
                        : "text-primary-500"
                    }`}
                  >
                    {b.state?.name}, {b.state?.country?.name}
                  </h5>
                  <p className="flex items-center gap-2 mb-2">
                    <LuPhone className="text-gray-500" /> {b.phone_number}
                  </p>
                  <p className="flex items-center gap-2 mb-2">
                    <LuCalendar className="text-gray-500" />
                    {b.created_at && formatDate(b.created_at)}
                  </p>
                  <p className="flex items-center gap-2">
                    <LuMapPin className="text-gray-500" />
                    {b.company_address}
                  </p>
                </div>
              ))}
            </div>

            <div className="flex items-center justify-center mt-6">
              <div className="w-[30%] mx-2">
                <Button
                  title="Register Branch"
                  loading={loading}
                  onHandler={() => showSet(false)}
                  className="bg-gray-300 text-gray-300"
                />
              </div>

              <div className="w-[30%] mx-2">
                <Button
                  title="Join Branch"
                  loading={loading}
                  onHandler={() => join_branch(dispatch, branch, navigate)}
                  className="bg-bggreen text-[white]"
                />
              </div>
            </div>
          </div>
        )}

        {!show && id && <AddBranch id={id || ""} />}
        {!company?.id && data.rc_number && (
          <AddCompany rc_number={data.rc_number || ""} />
        )}
      </div>
    </div>
  );
};

export default Company;
