import React from "react";

// components
import TextInput from "../../../components/elements/form/TextInput";
import Button from "../../../components/elements/form/Button";

// constants
import { FaLock, FaTimes } from "react-icons/fa";

// states
import { update_password } from "../../../service/api/auth";
import { useAppDispatch } from "../../../store";
import {
  IChangePassword,
  IChangePasswordError,
} from "../../../store/@types/user";
import { useNavigate } from "react-router-dom";
import Modal from "../../../components/ui/modal";

type IPasswordChange = {
  open: boolean;
  onClose: () => void;
};

const PasswordChange: React.FC<IPasswordChange> = ({ open, onClose }) => {
  const navigate = useNavigate;
  const dispatch = useAppDispatch();
  const [data, dataSet] = React.useState<IChangePassword>({
    password: "",
    password_confirmation: "",
    old_password: "",
  });
  const [error, errorSet] = React.useState<IChangePasswordError>({
    password: "",
    password_confirmation: "",
    old_password: "",
  });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dataSet((prev) => ({ ...prev, [name]: value }));
    errorSet((prev) => ({ ...prev, [name]: "" }));
  };

  const onSubmit = () => {
    let validate: boolean = false;
    if (!data.password) {
      validate = true;
      errorSet((prev) => ({ ...prev, password: "warning" }));
    }
    if (!data.old_password) {
      validate = true;
      errorSet((prev) => ({ ...prev, old_password: "warning" }));
    }
    if (data.password !== data.password_confirmation) {
      validate = true;
      errorSet((prev) => ({ ...prev, password_confirmation: "warning" }));
    }
    if (validate) return;
    update_password(dispatch, data, navigate);
  };

  return (
    <Modal open={open}>
      <div className="flex w-full items-center justify-end">
        <div className="">
          <FaTimes
            onClick={() => {
              onClose();
              dataSet({
                password: "",
                password_confirmation: "",
                old_password: "",
              });
            }}
            size={24}
            color="#9CA3AF"
            className="cursor-pointer"
          />
        </div>
      </div>

      <div className="my-3">
        <TextInput
          label="Old Password"
          required={true}
          name="old_password"
          value={data.old_password}
          type="password"
          placeholder="old_password"
          status={error.old_password}
          // icon={<FaLock />}
          onChange={onChange}
        />
      </div>

      <div className="my-3">
        <TextInput
          label="New Password"
          required={true}
          name="password"
          value={data.password}
          type="password"
          placeholder="Password"
          loading={false}
          status={error.password}
          icon={<FaLock />}
          onChange={onChange}
        />
      </div>

      <div className="my-3">
        <TextInput
          label="Confirm Password"
          required={true}
          name="password_confirmation"
          value={data.password_confirmation}
          type="password"
          placeholder="Confirm Password"
          loading={false}
          status={error.password_confirmation}
          icon={<FaLock />}
          onChange={onChange}
        />
      </div>

      <div className="flex justify-center">
        <div className="my-5 w-[50%]">
          <Button
            type="button"
            title="Change Password"
            className="bg-bggreen hover:text-[white] text-gray-300"
            onHandler={onSubmit}
          />
        </div>
      </div>
    </Modal>
  );
};

export default PasswordChange;
