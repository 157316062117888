import React from "react";
import { formatDate } from "../../../service/convert/date";
import { useNavigate } from "react-router-dom";
import { IBlog } from "../../../store/@types/blogs";
import { FaHeart, FaMessage, FaCalendar } from "react-icons/fa6";

type IBlogCard = {
  item: IBlog;
};

const BlogCard: React.FC<IBlogCard> = ({ item }) => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => navigate(`/blog/${item?.id}`)}
      className="w-full max-w-md mx-auto overflow-hidden rounded-tl-2xl rounded-br-2xl shadow-lg cursor-pointer transition-transform duration-300 bg-white hover:scale-105"
    >
      <div className="relative h-48 overflow-hidden">
        <img
          src={item?.image}
          alt={item?.title}
          className="object-cover w-full h-full transition-transform duration-300 "
        />
        <div className="absolute top-0 right-0 m-2">
          <span className="px-2 py-1 text-xs font-semibold text-white bg-primary-500 rounded-full">
            {item.category?.name}
          </span>
        </div>
      </div>

      <div className="p-4">
        <h3 className="mb-2 text-xl font-bold text-gray-800 hover:text-primary-500 line-clamp-2">
          {item?.title}
        </h3>
        <div className="flex items-center justify-between text-sm text-gray-600">
          <div className="flex items-center space-x-2">
            <FaCalendar size={16} />
            <span>{formatDate(item?.created_at)}</span>
          </div>
          <div className="flex items-center space-x-4">
            <div className="flex items-center space-x-1">
              <FaHeart size={16} className="text-red-500" />
              <span>{item.likes_count}</span>
            </div>
            <div className="flex items-center space-x-1">
              <FaMessage size={16} className="text-blue-500" />
              <span>{item.comments_count}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
