import axios, {
  // AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";

// app
import app from "../config/app";
// import { guest_path } from "../service/validate/guestpath";

const guest_routes = [
  "/login",
  "/register",
  "/training",
  "/forgot-password",
  "/blogs",
  "/about-us",
  "/trainings",
  "/services",
  "/",
];

const check_protected_routes = (pathname: string): boolean => {
  if (guest_routes.includes(pathname)) return false;
  // eslint-disable-next-line no-useless-escape
  const blog_route_regex = /^\/blog\/[a-fA-F0-9\-]{36}$/;
  if (blog_route_regex.test(pathname)) return false;
  return true;
};

let headers = {};

const axiosInstance = axios.create({
  baseURL: app.baseURL,
  headers,
});

axiosInstance.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    const token = localStorage.getItem("@serial");
    if (config.headers) {
      if (token) config.headers.Authorization = "bearer " + token;

      config.headers.Accept = "application/json";
      config.headers["Content-Type"] = "application/json";
    }
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) =>
    new Promise((resolve, _reject) => {
      resolve(response);
    }),
  (error) => {
    if (!error.response) {
      return new Promise((_resolve, reject) => {
        reject(error);
      });
    }
    if (
      error.response.status === 401 &&
      check_protected_routes(window.location.pathname)
    )
      window.location.href = "/login";

    return new Promise((resolve, reject) => {
      reject(error);
    });
  }
);

export default axiosInstance;
